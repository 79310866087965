<div class="container-fluid mt-4" style="padding-top: 17px">
  <div class="row">
    <p style="margin: 0; padding: 0; display: flex; flex-wrap: wrap; align-items: center;">
      Tổng phí CSKH: {{ calculateTotalAmount() | currency:'VND':'symbol':'1.0-0' }}
    </p>
  </div>
  
  <div class="row">
    <div class="col-lg-12" style="padding:0; margin:0;">
      <div class="table-responsive" style="max-height: 85vh; overflow-y: auto;">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Tên khách hàng</th>
              <th>Email</th>
              <th>Số điện thoại</th>
              <th>Địa chỉ</th>
              <th>Tuổi</th>
              <th>Giới tính</th>
              <th>Chẩn đoán</th>
              <th>Tiền thuốc</th>
              <th>Trạng thái</th>
              <th>Ngày tạo</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let plan of orderMedPlans">
              <td>{{plan.fullName}}</td>
              <td>{{plan.clientEmail}}</td>
              <td>{{plan.clientPhone}}</td>
              <td>{{plan.address}}</td>
              <td>{{plan.age}}</td>
              <td>{{plan.gender}}</td>
              <td>{{plan.diagnosis}}</td>
              <td>{{plan.medicationPay | currency:'VND':'symbol':'1.0-0' }}</td>
              <td>
                <span *ngIf="plan.status?.includes('8')" style="color: green; margin-left: 5px">✓</span>
              </td>
              <td>{{plan.createdAt?.toDate() | date:'dd/MM/yyyy HH:mm'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

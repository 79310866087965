import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { SearchResult, Plan } from '../interfaces/searchResult.interface';
import { ToastrService } from 'ngx-toastr';
import { DiamondService } from './diamond.service';
import { Timestamp } from 'firebase/firestore';
import axios, { AxiosResponse } from 'axios';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {

  private apiUrl = 'https://api.openai.com/v1/chat/completions';  // OpenAI API URL
  private apiKey = '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';  // Store your API key securely in a server for production use!

  private apiUrlG =
    'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=AIzaSyANCMq9sADHX2nTLf82jLklzSANfCHBT2c';

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private diamondService: DiamondService
  ) {}

  async processOpenAIResponse(
    prompt: string,
    userDocRef: any,
    userData: any,
    coins: number,
    useAIStudio: boolean
  ): Promise<SearchResult | null> {
    if (userData.diamonds < coins) {
      this.toastr.error('Bạn không đủ điểm!', 'Chưa lấy được hướng dẫn!');
      return null;
    }

    try {
      const response$ = useAIStudio
        ? this.getAdvanceCompletionG(prompt)
        : this.getAdvanceCompletion(prompt);

      const rawContent = await response$.toPromise();

      const success = await this.diamondService.spendDiamond(userDocRef, userData, coins);

      if (success) {
        this.toastr.success(`Bạn đã sử dụng ${coins} MevaCoin!`, 'Lấy hướng dẫn thành công!');
        userData.diamonds -= coins;
      }

      const jsonMatch = rawContent?.match(/\{.*\}/s);

      if (jsonMatch) {
        const parsedData = JSON.parse(jsonMatch[0]);
        const formattedData = this.formatOutputPlanData(parsedData);
        return formattedData;
      } else {
        this.toastr.error('Không tìm thấy JSON hợp lệ trong nội dung!', 'Lỗi!');
        return null;
      }
    } catch (error) {
      this.toastr.error('Lỗi khi gọi API hoặc xử lý dữ liệu!', 'Lỗi!');
      console.error(error);
      return null;
    }
  }

  private formatOutputPlanData(result: any): SearchResult {
    if (!result) {
      this.toastr.error('Dữ liệu trả về không tồn tại!', 'Lỗi!');
      return {
        clientEmail: '',
        createdAt: Timestamp.fromDate(new Date()),
        plans: [],
        guide: [],
        lab: [],
        medications: [],
        remark: ''
      }; 
    }

    const validateArray = (data: any, key: string): string[] => {
      if (Array.isArray(data[key])) {
        return data[key].filter((item: any) => typeof item === 'string');
      }
      return [];
    };

    const validateMedications = (data: any): any[] => {
      return Array.isArray(data.medications) ? data.medications.map(med => ({
        brandName: med.brandName,
        quantity: med.quantity,
        use: med.use,
      })) : [];
    };

    return {
      createdAt: Timestamp.fromDate(new Date()),
        clientEmail: '',
        providerEmail: 'AI Generated',
        guide: validateArray(result, 'guide'),
        lab: validateArray(result, 'lab'),
        medications: validateMedications(result),
        remark: typeof result.remark === 'string' ? result.remark : null,
        diagnosis: typeof result.diagnosis === 'string' ? result.diagnosis : null,
    };
  }

  getAdvanceCompletion(prompt: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}`
    });

    const body = {
      model: 'gpt-4o-mini',
      messages: [
        {
          role: 'system', content: "Bạn là một trợ lý bác sĩ giúp chuẩn bị tài liệu y khoa chuyên nghiệp dưới định dạng JSON."
        },
        {
          role: 'user',
          content: `Dựa trên tình trạng của bệnh nhân: "${prompt}", hãy trả về một hướng dẫn xử trí dưới định dạng JSON với các trường sau:
        {
          "diagnosis": "Mô tả chi tiết về chẩn đoán",
          "lab": ["Danh sách các xét nghiệm cần thực hiện để chẩn đoán chính xác"],
          "guide": ["Danh sách các hướng dẫn điều trị và can thiệp"],
          "medications": [
            {
              "brandName": "Tên và hàm lượng của thuốc",
              "quantity": Số_lượng,
              "use": "Hướng dẫn sử dụng thuốc"
            }
          ],
          "remark": "Nhận xét bổ sung nếu có"
        }.
        Lưu ý: Không nhắc lại các triệu chứng và tình trạng trong câu trả lời. Không kê các thuốc cùng một nhóm tác dụng để tránh quá liều thuốc. Không kê các thuốc có tương tác với nhau gây bất lợi cho người bệnh. Không kê các thuốc có trong tiền sử dị ứng của người bệnh.
        Chỉ trả về kết quả JSON. Không thêm bất kỳ nội dung nào khác.`
        }
      ],
    };

    return this.http.post<any>(this.apiUrl, body, { headers }).pipe(
      map((response) => response?.choices[0]?.message?.content || ''),
      catchError((error) => {
        console.error('Error calling OpenAI API:', error);
        throw new Error('Failed to process AI response.');
      })
    );
  }

  getAdvanceCompletionG(inputData: string): Observable<any> {
    const prompt = `Dựa trên tình trạng của bệnh nhân: "${inputData}", hãy trả về một hướng dẫn xử trí dưới định dạng JSON với các trường sau:
      {
        "diagnosis": "Mô tả chi tiết về chẩn đoán",
        "lab": ["Danh sách các xét nghiệm cần thực hiện để chẩn đoán chính xác"],
        "guide": ["Danh sách các hướng dẫn điều trị và can thiệp"],
        "medications": [
          {
            "brandName": "Tên và hàm lượng của thuốc",
            "quantity": "Số lượng",
            "use": "Hướng dẫn sử dụng thuốc"
          }
        ],
        "remark": "Nhận xét bổ sung nếu có"
      }.
      Lưu ý: Không nhắc lại các triệu chứng và tình trạng trong câu trả lời. Không kê các thuốc cùng một nhóm tác dụng để tránh quá liều thuốc. Không kê các thuốc có tương tác với nhau gây bất lợi cho người bệnh. Không kê các thuốc có trong tiền sử dị ứng của người bệnh.
      Chỉ trả về kết quả JSON. Không thêm bất kỳ nội dung nào khác.`;

    const payload = {
      contents: [
        {
          parts: [
            {
              text: prompt,
            },
          ],
        },
      ],
    };

    // Sử dụng axios.post và chuyển đổi Promise thành Observable
    const axiosPromise: Promise<AxiosResponse<any>> = axios.post(this.apiUrlG, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Chuyển đổi Promise thành Observable
    return from(axiosPromise).pipe(
      map((response) => response?.data?.candidates[0]?.content?.parts[0]?.text || ''),
      catchError((error) => {
        console.error('Error calling AI Studio API:', error);
        throw new Error('Failed to process AI response.');
      })
    );
  }

}

import { GoService } from './../../services/go.service';
import { Component, OnInit } from '@angular/core';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { getAuth, createUserWithEmailAndPassword } from '@angular/fire/auth'; // Import auth functions
import { getFirestore, Firestore, doc, setDoc } from '@angular/fire/firestore'; // Import Firestore functions
import { ToastrService } from 'ngx-toastr'; // Import ToastrService

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css'],
    standalone: false
})
export class RegistrationComponent implements OnInit {
  email = '';
  password = '';
  rePassword = '';

  icon = { envelope: faEnvelope, lock: faLock, facebook: faFacebookF, tweeter: faTwitter, google: faGoogle };

  private auth = getAuth(); // Khởi tạo auth
  private firestore: Firestore; // Đối tượng Firestore

  constructor(
    public go: GoService,
    private toastr: ToastrService // Inject ToastrService
  ) {
    this.firestore = getFirestore(); // Khởi tạo Firestore
  }

  ngOnInit(): void {}

  register() {
    this.password = this.password.trim();
    this.rePassword = this.rePassword.trim();

    if (this.password.length && this.rePassword.length && this.password === this.rePassword) {
      createUserWithEmailAndPassword(this.auth, this.email, this.password)
        .then(userCredential => {
          const user = userCredential.user;

          // Tạo document trong Firestore cho user
          setDoc(doc(this.firestore, 'User', user?.uid), {
            email: this.email,
            diamonds: 10,
            createdAt: new Date(),
            // Bạn có thể thêm các trường khác nếu cần
          })
          .then(() => {
            // Sau khi lưu thành công
            this.toastr.success('Đăng ký thành công! Vui lòng đăng nhập.', 'Thành công');
            this.go.login();
          })
          .catch(error => {
            // Xử lý lỗi khi lưu dữ liệu Firestore
            console.error('Error saving user data:', error);
            this.toastr.error('Có lỗi xảy ra khi lưu dữ liệu. Vui lòng thử lại.', 'Lỗi');
          });
        })
        .catch(reason => {
          // Xử lý lỗi khi tạo tài khoản Firebase
          switch (reason?.code) {
            case 'auth/email-already-in-use':
              this.toastr.warning('Email này đã được sử dụng. Vui lòng chọn email khác.', 'Cảnh báo');
              break;
            case 'auth/invalid-email':
              this.toastr.error('Email không hợp lệ. Vui lòng kiểm tra lại.', 'Lỗi');
              break;
            case 'auth/weak-password':
              this.toastr.warning('Mật khẩu quá yếu. Vui lòng chọn mật khẩu mạnh hơn.', 'Cảnh báo');
              break;
            default:
              this.toastr.error('Đã xảy ra lỗi. Vui lòng thử lại sau.', 'Lỗi');
              break;
          }
        });
    } else {
      // Xử lý khi mật khẩu không khớp
      if (this.password !== this.rePassword) {
        this.toastr.error('Mật khẩu và xác nhận mật khẩu không khớp.', 'Lỗi');
      } else {
        this.toastr.warning('Vui lòng điền đầy đủ thông tin.', 'Cảnh báo');
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  DocumentReference,
  DocumentData,
} from '@angular/fire/firestore';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { SearchResult, Plan } from '../../interfaces/searchResult.interface';
import { UserDocument } from '../../interfaces/user-document.interface';
import { User } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-my-account-detail',
    templateUrl: './my-account-detail.component.html',
    styleUrls: ['./my-account-detail.component.css'],
    standalone: false
})
export class MyAccountDetailComponent implements OnInit {

  user$: Observable<User | null>;
  userDocRef: DocumentReference | null = null;
    userData: UserDocument = {
    email: '',
    diamonds: 0, 
  };

  private unsubscribe: (() => void) | null = null;
  subcribePlans: SearchResult[];
  searchResults: SearchResult[] = [];
  selectedDocument: SearchResult | null = null;

  keyword: string = '';

  constructor(
    public docModal: NgbModal,
    private firestore: Firestore, // Sử dụng Firestore mới
    public authService: AuthService,
    private toastr: ToastrService,
  ) {
    this.user$ = this.authService.userSubject;
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (user) {
        this.userDocRef = doc(this.firestore, `User/${user.uid}`);

        onSnapshot(
          this.userDocRef,
          (userDocSnapshot) => {
            if (userDocSnapshot.exists()) {
              this.userData = userDocSnapshot.data() as UserDocument;
            } else {
              return;
            }
          },
          (error) => {
            console.error('Error fetching user data in realtime:', error);
          }
        );
      } else {
        this.userData = null;
      }
    });
    this.loadPlans(); 
  }

  private loadPlans() {
    const plansQuery = query(
      collection(this.firestore, 'plans'),
      where('status', 'array-contains', '6')
    );

    this.unsubscribe = onSnapshot(
      plansQuery,
      (plansSnapshot) => {
        this.subcribePlans = plansSnapshot.docs.map((doc) => ({
          documentId: doc.id, // Lấy ID của document
          ...doc.data(), // Lấy các dữ liệu khác trong document
        })) as SearchResult[];

        this.searchResults = this.subcribePlans.sort((a, b) => {
          const aTime = a.createdAt ? a.createdAt.toMillis() : 0;
          const bTime = b.createdAt ? b.createdAt.toMillis() : 0;
          return bTime - aTime;
        });
      },
      (error) => {
        console.error('Error fetching plans:', error);
      },
    );
  }

  async handleKeywordFilter() {
    console.log('Searching for keyword:', this.keyword); // Debugging
    this.selectedDocument = null;

    try {
      const results: SearchResult[] = [];
      const keywordLower = this.keyword
        ? this.keyword.toString().toLowerCase()
        : '';

      this.searchResults.forEach((data: SearchResult) => {
        const fullName = data.fullName
          ? data.fullName.toString().toLowerCase()
          : '';
        const clientPhone = data.clientPhone
          ? data.clientPhone.toString().toLowerCase()
          : '';
        const clientEmail = data.clientEmail
          ? data.clientEmail.toString().toLowerCase()
          : '';

        if (
          (fullName && fullName.includes(keywordLower)) ||
          (clientPhone && clientPhone.includes(keywordLower)) ||
          (clientEmail && clientEmail.includes(keywordLower))
        ) {
          results.push({
            ...data, // Thêm tất cả các trường dữ liệu từ tài liệu
          });
        }
      });
      if (results.length > 0) {
        this.searchResults = results;
      } else {
        this.searchResults = this.subcribePlans;
      }
    } catch (error) {
      console.error('Error during search:', error);
    }
  }

  selectDocument(document: SearchResult) {
    this.selectedDocument = document;
    console.log('Selected document:', document);
  }

  async updatePlan(medicationPay) {
    if (this.selectedDocument) {
      const documentId = this.selectedDocument.documentId;
      const updatedData = {
        medicationPay,
        updatedAt: Timestamp.now(),
      };

      try {
        const documentRef = doc(
          collection(this.firestore, 'plans'),
          documentId,
        ); // Lấy documentRef dựa trên documentId
        await updateDoc(documentRef, updatedData);
        this.toastr.success('Hãy xem lại số tiền chính xác!', 'Cập nhât thành công!');
      } catch (error) {
        console.error('Error updating document:', error);
      }
    }
  }

  async toggleStatus(plan: SearchResult, event: Event) {
    const inputElement = event.target as HTMLInputElement; // Ép kiểu để truy cập `checked`
    const isChecked = inputElement.checked;

    if (plan && plan.documentId) {
      const documentId = plan.documentId; // Giả sử `id` là trường chứa documentId của kế hoạch
      const updatedStatus = isChecked
        ? [...(plan.status || []), '8'] // Thêm "8" vào mảng nếu chưa có
        : (plan.status || []).filter((status) => status !== '8'); // Loại bỏ "8" khỏi mảng

        plan.status = updatedStatus;

        const updatedData = {
        status: updatedStatus,
        updatedAt: Timestamp.now(),
      };

      try {
        const documentRef = doc(this.firestore, 'plans', documentId);
        await updateDoc(documentRef, updatedData);
        this.toastr.success('Trạng thái thanh toán!', 'Cập nhật!');
      } catch (error) {
        console.error('Error updating document:', error);
      }
    }
  }

  calculateTotalAmount(): number {
    return this.searchResults
      .filter(plan => !plan.status?.includes('8')) // Exclude paid items
      .reduce((sum, plan) => sum + (plan.medicationPay || 0), 0);
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe(); // Gọi unsubscribe để hủy đăng ký
    }
  }
}


import '@angular/localize/init';

import 'zone.js';  // Included with Angular CLI.

import 'zone.js/plugins/webapis-shadydom';

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

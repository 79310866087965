import { Component, OnInit } from '@angular/core';
import { Firestore,  } from '@angular/fire/firestore';

@Component({
    selector: 'app-danh-sach-nha-cung-cap',
    templateUrl: './danh-sach-nha-cung-cap.component.html',
    styleUrls: ['./danh-sach-nha-cung-cap.component.css'],
    standalone: false
})
export class DanhSachNhaCungCapComponent implements OnInit {

  constructor(private firestore: Firestore) { }

  ngOnInit(): void {

  }

}

import { Injectable } from '@angular/core';
import { Firestore, collection, query, where, getDocs, DocumentReference, updateDoc } from '@angular/fire/firestore';
import { UserDocument } from '../interfaces/user-document.interface';

@Injectable({
  providedIn: 'root'
})
export class DiamondService {
  constructor(private firestore: Firestore) {}

  async sendDiamond(
    senderDocRef: DocumentReference, 
    senderData: UserDocument, 
    receiverEmail: string, 
    coins: number
  ): Promise<boolean> {
    try {
      // Kiểm tra số diamonds của người gửi
      if (senderData.diamonds < coins) {
        console.error('Người gửi không đủ diamonds để gửi.');
        return false;
      }

      // Tìm người nhận qua email
      const receiverQuery = query(
        collection(this.firestore, 'User'),
        where('email', '==', receiverEmail)
      );

      const receiverSnapshot = await getDocs(receiverQuery);

      if (!receiverSnapshot.empty) {
        const doc = receiverSnapshot.docs[0];
        const receiverData = doc.data() as UserDocument;

        // Cập nhật số diamonds của người nhận
        let receiverDiamonds = receiverData.diamonds || 0;
        receiverDiamonds += coins;

        // Cập nhật danh sách gifters
        const existingGifterIndex = receiverData.gifters?.findIndex(
          (gifter) => gifter.email === senderData.email
        ) ?? -1;

        const updateData: Partial<UserDocument> = {
          diamonds: receiverDiamonds,
        };

        if (existingGifterIndex === -1) {
          const newGifter = {
            email: senderData.email,
            lastSent: new Date().toISOString(),
            seen: false,
          };
          updateData.gifters = receiverData.gifters ? [...receiverData.gifters, newGifter] : [newGifter];
        } else if (receiverData.gifters) {
          receiverData.gifters[existingGifterIndex] = {
            ...receiverData.gifters[existingGifterIndex],
            lastSent: new Date().toISOString(),
            seen: false,
          };
          updateData.gifters = receiverData.gifters;
        }

        // Cập nhật người nhận
        const docRef = doc.ref as DocumentReference<UserDocument>;
        await updateDoc(docRef, updateData);

        // Cập nhật số diamonds của người gửi
        await updateDoc(senderDocRef, {
          diamonds: senderData.diamonds - coins
        });

        return true;
      }

      return false;
    } catch (error) {
      console.error('Error sending diamond:', error);
      return false;
    }
  }

  async purchaseWithDiamonds(
    userDocRef: DocumentReference, 
    userData: UserDocument, 
    propPrice: number,
    purchaseData: any
  ) {
    try {
      if (userData.diamonds < propPrice) {
        return false;
      }

      await updateDoc(userDocRef, {
        diamonds: userData.diamonds - propPrice,
        property: [...userData.property, purchaseData]
      });
      return true;
    } catch (error) {
      console.error('Error during purchase:', error);
      return false;
    }
  }

  async spendDiamond(userDocRef: DocumentReference, userData: UserDocument, coins: number) {
    try {
      if (userData.diamonds < coins) {
        return false;
      }
      await updateDoc(userDocRef, {
        diamonds: userData.diamonds - coins
      });
      return true;
    } catch (error) {
      console.error('Error during diamond spending:', error);
      return false;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { SearchResult } from '../../interfaces/searchResult.interface';
import { Router } from '@angular/router';

@Component({
    selector: 'app-quan-ly-thanh-toan',
    templateUrl: './quan-ly-thanh-toan.component.html',
    styleUrls: ['./quan-ly-thanh-toan.component.css'],
    standalone: false
})
export class QuanLyThanhToanComponent implements OnInit {
  orderMedPlans: SearchResult[] = [];

  constructor(
    private router: Router,
  ) { 
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.orderMedPlans = navigation.extras.state['orderMedPlans'].sort((a, b) => {
        return b.createdAt.toMillis() - a.createdAt.toMillis();
      });
    }
  }

  ngOnInit(): void {

  }

  calculateTotalAmount(): number {
    const totalAmount = this.orderMedPlans
      .filter(plan => plan.status?.includes('8')) // Lọc các mục có trạng thái đã thanh toán
      .reduce((sum, plan) => sum + (plan.medicationPay || 0), 0);

    return totalAmount * 0.05; // Trả về 1% của tổng số tiền
  }

  ngOnDestroy(): void {

  }
  
}

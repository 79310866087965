import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import * as BABYLON from 'babylonjs';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
  updateDoc,
  DocumentReference,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { UserDocument } from '../../../interfaces/user-document.interface';
import { SearchResult, Plan } from '../../../interfaces/searchResult.interface';
import { SceneSetupService } from '../../../services/babylon.service';
import { AuthService } from '../../../services/auth.service';
import { OpenAIService } from '../../../services/openai.service';
import { User } from '@angular/fire/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Timestamp } from 'firebase/firestore';
import { ToastrService } from 'ngx-toastr';

interface UploadedFile {
  url: string;
  name: string;
  type: string;
}

interface Problem {
  name: string;
  tag: string[];
}

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.css'],
    standalone: false
})
export class UserDetailComponent implements OnInit {
  @Input() canvas!: HTMLCanvasElement;
  @Input() userData!: UserDocument;
  @Input() allProblems: any[] = [];
  @Input() gameScene!: BABYLON.Scene;

  matchingProblems: Problem[] = [];

  user$: Observable<User | null>;
  userDocRef: DocumentReference | null = null;

  private userDocSnapshot: any;

  clientPlanSubscription: any;
  orderList: SearchResult[];
  clientPlansListOpen: boolean = true;
  selectedDocument: SearchResult | null = null;

  transcriptText: string = '';

  private balls: BABYLON.Mesh[] = [];
  pickedProblems: Set<string> = new Set();
  public plansProblem: Plan[] = [];
  gameOverDiv: boolean = false;
  buttonFind: boolean = true;
  
  labInput: string = '';
  
  outputPlanData: SearchResult | null = null;

  loadingFileList: boolean = false;
  loadingFileSelectedOther: boolean;
  currentViewedFile: UploadedFile | null = null;

  showPlanDocList: boolean = true;

  constructor(
    public ngZone: NgZone,
    public firestore: Firestore,
    private sceneSetupService: SceneSetupService,
    public docModal: NgbModal,
    public authService: AuthService,
    private openAIService: OpenAIService,
    private toastr: ToastrService,
  ) {
    this.user$ = this.authService.userSubject;
  }

  ngOnInit(): void {
    
    this.user$.subscribe((user) => {
      if (user) {
        this.userDocRef = doc(this.firestore, `User/${user.uid}`);
        this.loadUserPlans(user.email);
      }
    });
    
  }

  ngAfterViewInit(): void {

  }

  private loadUserPlans(email: string) {
      const clientPlansQuery = query(
        collection(this.firestore, 'plans'),
        where('clientEmail', '==', email),
      );

      this.clientPlanSubscription = onSnapshot(
        clientPlansQuery,
        (plansSnapshot) => {
          this.orderList = plansSnapshot.docs.map((doc) => ({
            documentId: doc.id,
            createdAt: doc.data().createdAt,
            clientEmail: doc.data().clientEmail,
            providerEmail: doc.data().providerEmail,
            guide: doc.data().guide || [],
            medications: doc.data().medications || [],
            status: doc.data().status || [],
            files: doc.data().files || []
          })) as SearchResult[];
          
          this.orderList.sort((a, b) => {
            if (!a.createdAt) return 1;
            if (!b.createdAt) return -1;
            const dateA = a.createdAt instanceof Timestamp ? a.createdAt.toDate() : a.createdAt;
            const dateB = b.createdAt instanceof Timestamp ? b.createdAt.toDate() : b.createdAt;
            return dateB.getTime() - dateA.getTime();
          });
        }
      );
    
  }

  selectDocument(document: SearchResult) {
    this.currentViewedFile = null;
    this.clientPlansListOpen = false;
    this.selectedDocument = document;
  }

  viewUploadedFile(file: UploadedFile) {
    this.currentViewedFile = {
      url: file.url, // Sử dụng URL đã có trong đối tượng UploadedFile
      type: file.type.startsWith('image/') ? 'image' : 'other',
      name: file.name,
    };
  }

  contentLoaded() {
    this.loadingFileSelectedOther = false;
  }

  async updatePlan() {
    if (this.selectedDocument) {
      const documentId = this.selectedDocument.documentId; // Lấy documentId từ selectedDocument
      const updatedStatuses = [...(this.selectedDocument.status || [])]; // Sao chép mảng hiện tại
      if (!updatedStatuses.includes('6')) { 
        updatedStatuses.push('6'); // Thêm giá trị '6' nếu chưa có
      }

      const updatedData = {
        status: updatedStatuses,
        updatedAt: Timestamp.now(),
      };

      try {
        const documentRef = doc(
          collection(this.firestore, 'plans'),
          documentId,
        ); // Lấy documentRef dựa trên documentId
        await updateDoc(documentRef, updatedData);
        this.toastr.success('Chúng tôi sẽ liên hệ lại!', 'Đặt mua thuốc thành công!');
      } catch (error) {
        console.error('Error updating document:', error);
      }
    }
  }

  async stop() {
    
    this.gameOverDiv = false;
    this.ngZone.runOutsideAngular(() => {
      // Xóa bóng cũ với hiệu ứng
      this.balls.forEach((b) => this.sceneSetupService.fadeOutAndDispose(b));
      this.balls = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
      this.outputPlanData = null;
    });

    // Hiển thị prompt để người dùng nhập thêm thông tin
    this.transcriptText = window.prompt('Nhập chữ hoặc dùng micro bàn phím:', '');

    const words = [
      this.transcriptText?.toLowerCase() || '',
      this.selectedDocument.underlying || '',
      (this.selectedDocument.lab || []).join(' '), // Nếu lab là mảng
    ].join(' ').toLowerCase() || '';

    const matchingProblems = await this.allProblems.filter(
      (problem) =>
        Array.isArray(problem.tag) &&
        problem.tag.some((tag) => words.includes(tag.toLowerCase()))
    );

    this.matchingProblems = Array.from(
      new Map(matchingProblems.map((problem) => [problem.id, problem])).values()
    );

    if (this.matchingProblems.length === 0) {
      this.toastr.error('Hãy nhập thêm thông tin!', 'Không vấn đề phù hợp:');
      return;
    }

    this.createBalls(this.gameScene);
    this.showPlanDocList = false;
    this.toastr.info('Xanh lá cây là bình thường!', 'Đánh giá rối loạn:');
  }

  private createBalls(scene: BABYLON.Scene): void {
    const availableProblems = this.matchingProblems.filter(
      (problem) => !this.pickedProblems.has(problem.name)
    );

    if (availableProblems.length > 0) {
      const randomProblem = availableProblems[Math.floor(Math.random() * availableProblems.length)];

      // Xử lý click bóng
      const onPickCallback = (option: number) => {
        this.ngZone.run(() => {

          this.plansProblem = this.plansProblem.filter(
            (plan) => plan.name !== randomProblem.name
          );
          this.plansProblem.push({
            name: randomProblem.name,
            option,
          });

          this.pickedProblems.add(randomProblem.name);

          // Xóa bóng cũ với hiệu ứng
          this.balls.forEach((b) => this.sceneSetupService.fadeOutAndDispose(b));
          this.balls = [];

          if (this.pickedProblems.size < this.matchingProblems.length) {
            this.createBalls(scene);
          } else {
            this.endGame();
          }
        });
      };

      // Gọi service tạo bóng
      this.balls = this.sceneSetupService.createBalls(
        scene,
        randomProblem,
        this.pickedProblems,
        onPickCallback
      );
    } else if (this.pickedProblems.size > 0) {
      this.endGame();
    }
  }

  private endGame() {
    this.balls.forEach((ball) => ball.dispose());
    this.balls = [];
    this.gameOverDiv = true;
  }

  public replay() {
    this.gameOverDiv = false;

    this.ngZone.runOutsideAngular(() => {
      this.balls = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
    });
    this.buttonFind = true;
    this.createBalls(this.gameScene);
  }

  async getOpenAIResponse() {
    const medRecords = `
      Năm sinh: ${this.selectedDocument.age || 'Chưa có'}.
      Giới tính: ${this.selectedDocument.gender || 'Chưa có'}.
      Tiền sử: ${this.selectedDocument.underlying || 'Chưa có'}.
      Với các thông tin từ phiên khám ngày: ${this.selectedDocument.createdAt || 'Chưa có'} là:
      Diễn biến: ${this.selectedDocument.history || 'Chưa có'}.
      Một số triệu chứng kèm theo mức độ là: ${this.selectedDocument.plans || 'Chưa có'}.
      Xét nghiệm: ${this.selectedDocument.lab || 'Chưa có'}.
      Chẩn đoán: ${this.selectedDocument.diagnosis || 'Chưa có'}.
      Hướng dẫn: ${this.selectedDocument.guide || 'Chưa có'}.      
      Thuốc: ${JSON.stringify(this.selectedDocument.medications || [])}.
      Ghi chú: ${this.selectedDocument.remark || 'Chưa có'}.
      Và tình trạng hiện tại là: 
      Diễn biến: ${this.transcriptText || 'Chưa có'}.
      Trong đó, mức độ một số triệu chứng là: ${this.plansProblem || 'Chưa có'}.
      Xét nghiệm: ${this.labInput || 'Chưa có'}.
      Lưu ý: Mức độ các triệu chứng được quy định bán định lượng như sau: 1: Hoàn toàn bình thường. 2: Mức độ nhẹ, cần theo dõi. 3: Mức độ vừa, có thể trì hoãn can thiệp. 4: Mức độ nặng, cần can thiệp ngay. 5: Mức độ rất nặng, cần can thiệp khẩn cấp.
    `;

    if (!medRecords.trim()) {
      this.toastr.error('Không có dữ liệu hợp lệ để gửi!', 'Lỗi!');
      return;
    }

    this.buttonFind = false;

    const coins = 3;

    const result = await this.openAIService.processOpenAIResponse(
      medRecords,
      this.userDocRef,
      this.userData,
      coins,
      false
    );

    if (result) {
      this.outputPlanData = result; // Gán dữ liệu sau khi xử lý
    } else {
      this.toastr.error('Không thể lấy dữ liệu!');
    }
  }

  closeGameOverDiv() {
    this.gameOverDiv = false;
    this.selectedDocument = null;
    this.transcriptText = '';
    this.labInput = '';
    this.plansProblem = [];
    this.pickedProblems.clear();
    this.matchingProblems = [];
    this.outputPlanData = null;
    this.showPlanDocList = true;
  }

  private adjustUI() {
    const buttons = document.querySelectorAll('button');

    buttons.forEach((button) => {
      (button as HTMLElement).style.width = '100%';
      (button as HTMLElement).style.bottom = '10px'; // Điều chỉnh khoảng cách từ đáy
    });
  }

  ngOnDestroy(): void {

    if (this.clientPlanSubscription) {
      this.clientPlanSubscription();
    }

  }

  clearBalls() {
    if (this.balls.length > 0) {
      this.balls.forEach((b) => this.sceneSetupService.fadeOutAndDispose(b));
      this.balls = [];
      this.plansProblem = [];
      this.pickedProblems.clear();
      this.outputPlanData = null;
    }
  }

}

import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { VideoCallService } from '../services/video-call.service';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.css'],
    standalone: false
})
export class RootComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public videoCallService: VideoCallService) {}

  ngOnInit() {
    // Chỉ các thao tác không phụ thuộc DOM mới thực hiện ở đây
  }

  ngAfterViewInit() {
    // Các thao tác liên quan DOM hoặc video frame
    this.videoCallService.listenToCallsData();
  }

  ngOnDestroy() {
    if (this.videoCallService.unsubscribeCallsData) {
      this.videoCallService.unsubscribeCallsData();
    }
  }
}


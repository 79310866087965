<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
</head>

<div class="game-container">
  <canvas #renderCanvas touch-action="none"></canvas>
  
  <div *ngIf="gameOverDiv" class="game-over">
    <button (click)="replay()">Đánh giá lại</button>
    <div *ngIf="!outputPlanData">
      <h2>Tình trạng sức khỏe</h2>      
      <ul>
        <li *ngFor="let problem of plansProblem">
          <span>{{ problem.name }}</span>
          <span class="container-status">          
            <span *ngIf="problem.option == 1" class="dot-status green"></span>         
            <span *ngIf="problem.option == 2" class="dot-status cyan"></span>
            <span *ngIf="problem.option == 3" class="dot-status yellow"></span>
            <span *ngIf="problem.option == 4" class="dot-status orange"></span>
            <span *ngIf="problem.option == 5" class="dot-status red"></span>
          </span>
        </li>
      </ul>
    </div>

    <div *ngIf="outputPlanData">
      <div *ngIf="outputPlanData.diagnosis">
        <h3>Chẩn đoán</h3>
        <p>{{ outputPlanData.diagnosis }}</p>
      </div>
      <div *ngIf="outputPlanData.guide?.length">
        <h3>Hướng dẫn</h3>
        <ul class="left-aligned-list">
          <li *ngFor="let guide of outputPlanData.guide">
            {{ guide }}
          </li>
        </ul>
      </div>
      <div *ngIf="outputPlanData.lab?.length">
        <h3>Xét nghiệm cần làm</h3>
        <ul class="left-aligned-list">
          <li *ngFor="let lab of outputPlanData.lab">
            {{ lab }}
          </li>
        </ul>
      </div>
      <div *ngIf="outputPlanData.medications?.length">
        <h3>Thuốc</h3>
        <ul class="left-aligned-list">
          <li *ngFor="let med of outputPlanData.medications">
            <strong>{{ med.brandName }}</strong> - 
            Số lượng: {{ med.quantity }} - 
            Hướng dẫn: {{ med.use }}
          </li>
        </ul>
      </div>      
      <div *ngIf="outputPlanData.remark">
        <h3>Ghi chú</h3>
        <p>{{ outputPlanData.remark }}</p>
      </div>
    </div>

    <button *ngIf="buttonFind" (click)="getGptGuide()">Xem hướng dẫn</button>
    <button *ngIf="!buttonFind" (click)="callDoctor()">Liên hệ khẩn cấp</button>
  </div>

  <div id="diamonds" (click)="authService.signOut()">
    <ng-container *ngIf="userData; else loginPrompt">
      &#65504; {{ userData.diamonds }}
    </ng-container>
    <ng-template #loginPrompt>
      Đăng nhập
    </ng-template>
  </div>

  <app-user-list  [userData]="userData" [hidden]="!goMarket"></app-user-list>
  <app-user-detail 
  #userDetail
  [hidden]="!goDetail" 
  [canvas]="renderCanvas" 
  [userData]="userData" 
  [allProblems]="allProblems"
  [gameScene]="gameScene">
</app-user-detail>
</div>

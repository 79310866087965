import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  canActivate,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';

// Import các component
import { RegistrationComponent } from './auth/registration/registration.component';
import { LoginComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const redirectUnauthorized = () => redirectUnauthorizedTo(['dang-nhap']);
const redirectLoggedIn = () => redirectLoggedInTo(['trang-chu']);

const routes: Routes = [
  {
    path: 'dang-ky',
    component: RegistrationComponent,
    ...canActivate(redirectLoggedIn),
  },
  {
    path: 'dang-nhap',
    component: LoginComponent,
    ...canActivate(redirectLoggedIn),
  },
  { path: 'login', redirectTo: 'dang-nhap', pathMatch: 'full' },
  { path: 'registration', redirectTo: 'dang-ky', pathMatch: 'full' },
  {
    path: 't',
    loadChildren: () =>
      import('./root/root.module').then((m) => m.RootModule), // Lazy Loading
  },
  { path: 'trang-chu', redirectTo: 't', pathMatch: 'full' },
  { path: '', redirectTo: 't', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
